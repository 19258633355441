import React, { useEffect, useState } from "react";
import { Card, Table, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  deleteState,
  getPrices,
  getStates,
} from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import UpdatePrice from "../modal/UpdatePrice";
import AddStates from "../modal/AddStates";
export default function PriceManagement(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [states, setStates] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount2, setPageCount2] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const limit = 10;
  const limit2 = 10;
  const [listLength2, setListLength2] = useState("");
  const [listLength, setListLength] = useState("");
  const [id, setId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalStates, setShowModalStates] = useState(false);

  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getPrices(currentPage, limit);
      console.log(response.data.data);
      setUsers(response.data.data?.data);
      setLoader(false);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(total);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };
  const getTableDataStates = async () => {
    setLoader(true);
    try {
      const response = await getStates(currentPage2, limit);
      console.log(response.data.data);
      setStates(response.data.data?.data);
      setLoader(false);
      const total = response.data.data.count;
      setPageCount2(Math.ceil(total / limit2));
      setListLength2(total);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };

  async function onAction(id) {
    setLoader(true);
    try {
      const response = await deleteState(id);
      notifyTopRight(response.data?.message);
      await getTableDataStates();
      setLoader(false);
      console.log(response);
    } catch (error) {
      setLoader(false);
      notifyError(error.response?.data?.data);
    }
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);
  useEffect(() => {
    getTableDataStates();
  }, [currentPage2]);

  return (
    <div>
      <PageTitle activeMenu="Price Management" motherMenu="Price Management" />
      <Card>
        <Card.Header className="">
          <Card.Title>Exempted Cities/States </Card.Title>
          <button
            type="button"
            className="btn btn-primary px-3 py-2 fs-14"
            onClick={() => setShowModalStates(true)}
          >
            Add +
          </button>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>City/State</strong>
                  </th>

                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {states?.map((item, i) => (
                  <tr key={i}>
                    <td>{item?.name}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        style={{ cursor: "pointer" }}
                        onClick={() => onAction(item?._id)}
                      >
                        <i className="flaticon-381-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {states?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          ) : (
            ""
          )}
          {states?.length === 0 ? (
            ""
          ) : (
            <div className="card-footer clearfix">
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info ">
                  Showing {currentPage2 * limit2 + 1} to{" "}
                  {listLength2 > (currentPage2 + 1) * limit2
                    ? (currentPage2 + 1) * limit2
                    : listLength2}{" "}
                  of {listLength2} entries
                </div>
                <Pagination
                  pageCount={pageCount2}
                  pageValue={currentPage2}
                  setPage={setCurrentPage2}
                />
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      <Card>
        <Card.Header className="d-block">
          <Card.Title>Price Table</Card.Title>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>Vendor</strong>
                  </th>
                  <th>
                    <strong>Multiplier Price</strong>
                  </th>
                  <th>
                    <strong>Action</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item, i) => (
                  <tr key={i}>
                    <td>{item?.name}</td>
                    <td>{item?.mupltiplier ? item?.mupltiplier : "--"}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary px-2 py-2"
                        onClick={() => {setId(item);setShowModal(true)}}
                      >
                        Update Price
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {users?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          ) : (
            ""
          )}
          {users?.length === 0 ? (
            ""
          ) : (
            <div className="card-footer clearfix">
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info ">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </Card.Body>
      </Card>

      {showModal && (
        <UpdatePrice
          show={showModal}
          id={id}
          table={getTableData}
          onHide={() => setShowModal(false)}
        />
      )}
      {showModalStates && (
        <AddStates
          show={showModalStates}
          table={getTableDataStates}
          onHide={() => setShowModalStates(false)}
        />
      )}
    </div>
  );
}
