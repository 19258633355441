export const GET_USERS = 'admin/userManage/users';

export const DELETE_USERS = 'admin/auth/userDelete';

export const BLOCK_USERS = 'admin/userManage/blockUser';

export const APPROVE_USER_DETAILS = 'admin/auth/userApproved';

export const POST_USER_DETAILS = 'admin/auth/signupUser';


///////////////===================== events=======///////////////

export const GET_EVENT = 'admin/userManage/listArtists';

export const DELETE_EVENT = 'admin/userManage/deleteArtist';

export const ADD_EVENT = 'admin/userManage/artist';

/////////////=====================price================///////////////////////

export const GET_PRICES = 'admin/userManage/getPrices';

export const UPDATE_PRICES = 'admin/userManage/updateVendors';

export const UPDATE_PRICES2 = 'admin/userManage/updatePrices';

export const GET_STATES = 'admin/userManage/listStates';

export const POST_STATES = 'admin/userManage/states';

export const DELETE_STATES = 'admin/userManage/deleteStates';