import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import addPhoto, {
  addState,
  createEvent,
} from "../../services/User/UserService";
import countryList from "react-select-country-list";
import Select from "react-select";
import { RegionDropdown } from "react-country-region-selector";
import countryCurrencyMap, { getCountry } from "country-currency-map";
export default function AddStates({ show, table, onHide }) {
  let errorsObj = {state: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  console.log(state, "country");
  const [loader, setLoader] = useState(false);

  const options = useMemo(() => {
    const countryOptions = countryList().getData();
    // Add currency information to each country option
    return countryOptions.map((country) => {
      const currencyInfo = getCountry(country.label); // Retrieve currency info
      const currency = currencyInfo ? currencyInfo.currency : ""; // Extract currency
      // console.log(currency, "currency"); // Debugging: Log country code and currency
      return {
        ...country,
        currency: currency,
      };
    });
  }, []);
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (state === "") {
      errorObj.state = "This field is required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
        const formData={name:state}
      const response = await addState(formData);
      notifyTopRight(response?.data?.message);
      setState("");
      table();
      onHide();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={onSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Add Details</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Country</label>
            <Select
              placeholder="Country"
              options={options}
              value={country}
              onChange={(value) => setCountry(value)}
            />
            {errors?.country && (
              <div className="text-danger fs-12">{errors?.country}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="text-black font-w500">States</label>
            <RegionDropdown
              className="form-control"
              country={country.label}
              value={state}
              name="state"
              onChange={(e) => {
                setState(e);
                setErrors({ ...errors, state: "" });
              }}
            />
            {errors?.state && (
              <div className="text-danger fs-12">{errorsObj?.state}</div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
          <button type="button" onClick={onHide} className="btn btn-secondary">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
}
