import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { updatePrice, updateVendor } from "../../services/User/UserService";
export default function UpdatePrice({ show, id, table, onHide }) {
  let errorsObj = { image: "", name: "", type: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    id: "",
    value: "",
  });

  const [loader, setLoader] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleFetch = async () => {
    setLoader(true);
    try {
      const response = await updateVendor(id?.name);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData?.value === "") {
      errorObj.price = "This field is required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const response = await updatePrice(formData.id, formData?.value);
      notifyTopRight(response?.data?.message);
      setFormData({ id: "", value: "" });
      table();
      onHide();
      handleFetch();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  useEffect(() => {
    setFormData({ ...formData, id: id?._id });
  }, [id]);
  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={onSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Add Details</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Multiplier Price</label>
            <input
              type="number"
              className="form-control"
              name="value"
              value={formData.value}
              onChange={handleInput}
              min={0}
              step="0.01" 
            />
            {errors?.price && (
              <div className="text-danger fs-12">{errors?.price}</div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
          <button type="button" onClick={onHide} className="btn btn-secondary">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
}
